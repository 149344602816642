import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import React, {Suspense, lazy, Fragment} from 'react';
import Loader from 'react-loaders'

import {
    ToastContainer,
} from 'react-toastify';

const ShowPlayer = lazy(() => import('../../Pages/PlayerUI/'));
const ShowChannel = lazy(() => import('../../Pages/ChannelUI'));
const WelcomeUI = lazy(() => import('../../Pages/WelcomeUI'));

const AppMain = () => {
    return (
        <Fragment>

            {/* Dashboards */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-beat" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/show" component={ShowPlayer} />
                <Route path="/channel" component={ShowChannel} />
                <Route path="/welcome" component={WelcomeUI} />
                <Route path="/Embed" component={ShowPlayer} />
                <Route path="/emchannel" component={ShowChannel} />
                
            </Suspense>

            <Route exact path="/" render={() => (
                <Redirect to="/Welcome" />
            )} />
            <ToastContainer />
        </Fragment>
    )
};

export default AppMain;

